import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {never, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  apiUrl = 'https://asjxcaqp03.execute-api.us-west-2.amazonaws.com/prod/';
  apiKey = '5061kDFOQ24rMqVeZSpW1C0dUbRKJ1zaWilRdD3b';
  neverBounceKey = 'secret_3801c683bb2ef0ac23aa42d8400c7227';

  constructor(private http: HttpClient) { }

  verifyEmail(email: string) {
    try {
      return this.http.get(this.apiUrl + 'signup/verifyemail?email=' + email + '&secretKey=' + this.neverBounceKey,
        {headers: new HttpHeaders({'X-API-KEY': this.apiKey}), observe: 'response'});
    } catch (e) {
      console.log(e);
    }
  }
  verifyAccount(email: string, rewardID: string) {
    try {
      return this.http.get(this.apiUrl + 'signin/verifyaccount?email=' + email + '&rewardID=' + rewardID,
        {headers: new HttpHeaders({'X-API-KEY': this.apiKey}), observe: 'response'});
    } catch (e) {
      console.log(e);
    }
  }
  generatePassword() {
    try {
      return this.http.get(this.apiUrl + 'signup/password',
        {headers: new HttpHeaders({'X-API-KEY': this.apiKey}), observe: 'response'});
    } catch (e) {
      console.log(e);
    }
  }
  resetPassword(email: string, firstName: string, lastName: string, password: string, rewardID: string, verified: boolean) {
    try {
      return this.http.post(
        this.apiUrl +
        'signup/password?email=' + email +
        '&firstName=' + firstName +
        '&lastName=' + lastName +
        '&password=' + password +
        '&rewardID=' + rewardID +
        '&verified=' + verified,
        null,
        {headers: new HttpHeaders({'X-API-KEY': this.apiKey}), observe: 'response'});
    } catch (e) {
      console.log(e);
    }
  }
  alreadyTaken(email: string) {
    try {
      return this.http.post(this.apiUrl + 'signup/verifyemail?email=' + email + '&secretKey=' + this.neverBounceKey, null,
        {headers: new HttpHeaders({'X-API-KEY': this.apiKey}), observe: 'response'});
    } catch (e) {
      console.log(e);
    }
  }
  signUp(email: string, first: string, last: string, country: string, zip: string, password: string, rewardID: string, facilityID: string, neverbounceResult: string) {
    try {

      const lastNameURI = encodeURIComponent(last);
      const firstNameURI = encodeURIComponent(first);
      const zipURI = encodeURIComponent(zip);
      const countryURI = encodeURIComponent(country);
      const emailURI = encodeURIComponent(email);
      const facilityIDURI = encodeURIComponent(facilityID);
      const contactTypeIDURI = encodeURIComponent('6');
      const rewardIDURI = encodeURIComponent(rewardID);
      const passwordURI = encodeURIComponent(password);
      const rewardLevelNumberURI = encodeURIComponent('4');
      const empInitialsURI = encodeURIComponent('ONL');
      const neverbounceResultURI = encodeURIComponent(neverbounceResult);

      const queryParameterString =
        'lastName=' + lastNameURI +
        '&firstName=' + firstNameURI +
        '&address=' +
        '&city=' +
        '&state=' +
        '&zip=' + zipURI +
        '&country=' + countryURI +
        '&email=' + emailURI +
        '&facilityID=' + facilityIDURI +
        '&contactTypeID=' + contactTypeIDURI +
        '&rewardID=' + rewardIDURI +
        '&password=' + passwordURI +
        '&rewardLevel=' + rewardLevelNumberURI +
        '&empInitials=' + empInitialsURI +
        '&phoneNumber=' + '' +
        '&validation=' + neverbounceResultURI;

      return this.http.post(this.apiUrl + 'signup/troontemp?' + queryParameterString, null,
        {headers: new HttpHeaders({'X-API-KEY': this.apiKey}), observe: 'response'});
    } catch (e) {
      console.log(e);
    }
  }

  generateRewardID() {
    try {
      return this.http.get(this.apiUrl + 'signup/rewardid',
        {headers: new HttpHeaders({'X-API-KEY': this.apiKey}), observe: 'response'});
    } catch (e) {
      console.log(e);
    }
  }

  generatePass(last: string, first: string, rewardID: string) {
    try {
      const lastNameURI = encodeURIComponent(last);
      const firstNameURI = encodeURIComponent(first);
      const rewardIDURI = encodeURIComponent(rewardID);

      const queryParameterString =
        'lastName=' + lastNameURI +
        '&firstName=' + firstNameURI +
        '&rewardID=' + rewardIDURI;

      return this.http.post(this.apiUrl + 'applewalletexisting?' + queryParameterString, null,
        {headers: new HttpHeaders({'X-API-KEY': this.apiKey}), observe: 'response'})
    } catch (e) {
      console.log(e);
    }
  }

  getRewardID(email: string) {
    try {
      const emailURI = encodeURIComponent(email);
      return this.http.post(this.apiUrl + 'signup/rewardidexisting?email=' + emailURI, null,
        {headers: new HttpHeaders({'X-API-KEY': this.apiKey}), observe: 'response'});
    } catch (e) {
      console.log(e);
    }
  }

  rewardIDEmail(rewardID: string, email: string) {
    try {
      return this.http.post(this.apiUrl + 'signup/rewardidemail?rewardID=' + rewardID + "&email=" + email, null,
        {headers: new HttpHeaders({'X-API-KEY': this.apiKey}), observe: 'response'});
    } catch (e) {
      console.log(e);
    }
  }

}
