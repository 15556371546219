import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NgForm} from '@angular/forms';
import {DialogService} from './dialog.service';
/*  THIS IS WHERE WE COULD SEND DATA TO DIALOG
export interface DialogData {
  animal: string;
  name: string;
}
*/
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  emailShow = false;
  loading = false;
  success = false;
  failed = false;
  isInvalid = false;
  valid = false;
  validating = false;
  alreadyTaken = false;
  emailSuccess = false;
  emailFailed = false;
  verifyEntrySubscription: Subscription;
  rewardIDEmailSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>/*,@Inject(MAT_DIALOG_DATA) public data: DialogData*/, private dialogService: DialogService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkMark(form: NgForm) {
    if (form.value.email !== '') {
      this.emailShow = true;
    } else {
      this.emailShow = false;
    }
  }

  onReset(form: NgForm) {
    this.loading = true;
    this.success = false;
    this.failed = false;
    console.log(form.value);
    console.log(form.value.email);

    this.verifyEntrySubscription = this.dialogService.successSubject
      .subscribe(
        (result) => {
          if (!result) {
            this.validating = false;
            this.isInvalid = true;
            this.valid = false;
          } else {
            this.validating = false;
            this.isInvalid = false;
            this.valid = true;
            this.emailShow = false;
            this.loading = false;
          }
        },
        (err) => console.log(err)
      );
    this.rewardIDEmailSubscription = this.dialogService.emailSubject
      .subscribe(
        (result) => {
          if (!result) {
            this.emailFailed = true;
          } else {
            this.emailSuccess = true;
          }
        }
      );
    this.dialogService.verifyEntry(form.value.email);
  }

}
