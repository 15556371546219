import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';
import {countries} from 'typed-countries';
import {SignupService} from './signup.service';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  animations: [
    trigger('iconStateOne', [
      state('invalid', style({
        transform: 'scale(1)'
      })),
      state('valid', style({
        transform: 'scale(0)'
      })),
      transition('invalid <=> valid', animate(300))
    ])
  ]
})
export class SignupComponent implements OnInit {
  blurSubscription: Subscription;
  alreadyTakenSubscription: Subscription;
  passwordSubscription: Subscription;
  rewardIDSubscription: Subscription;
  successSubscription: Subscription;
  errorSubscription: Subscription;
  isInvalid = false;
  valid: boolean;
  validating = false;
  alreadyTaken = false;
  email = '';
  isSuccessful = false;
  countriesArray: string[] = ['United States'];
  password: string;
  rewardID: string;
  currentIcon = 'fiber_manual_record';
  icons: string[] = [
    'fiber_manual_record',
    'check_circle'
  ];
  state = 'invalid';
  inverseState = 'valid';
  emailShow = true;
  firstShow = true;
  lastShow = true;
  countryShow = true;
  zipShow = true;
  facilityID: string;
  neverbounceResult: string;

  constructor(private signupService: SignupService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.facilityID = params['facilityID'];
        }
      );
    this.blurSubscription = this.signupService.emailSubject
      .subscribe(
        (result) => {
          this.neverbounceResult = result;
          if (result === 'invalid' || result === 'disposable') {
            this.validating = false;
            this.isInvalid = true;
            this.valid = false;
          } else if (result === 'catchall' || result === 'unknown') {
            this.validating = false;
            this.valid = false;
            this.isInvalid = false;
          } else {
            this.validating = false;
            this.isInvalid = false;
            this.valid = true;
            this.emailShow = false;
          }
        },
        (err) => console.log(err)
      );
    this.alreadyTakenSubscription = this.signupService.alreadyTakenSubject
      .subscribe(
        (response) => {
          if (response > 0) {
            this.alreadyTaken = true;
          } else {
            this.alreadyTaken = false;
          }
        }
      );
    this.passwordSubscription = this.signupService.passwordSubject
      .subscribe(
        (result) => {
          this.password = result;
        },
        (err) => console.log(err)
      );
    this.rewardIDSubscription = this.signupService.rewardIDSubject
      .subscribe(
        (result) => this.rewardID = result,
        (err) => console.log(err)
      );
    this.successSubscription = this.signupService.successSubject
      .subscribe(
        (bool) => {
          this.isSuccessful = bool;
          this.signupService.generatePasswordAndRewardID();
        },
        (err) => console.log(err)
      );
    this.errorSubscription = this.signupService.errorSubject
      .subscribe(
        (bool) => this.isInvalid = bool,
        (err) => console.log(err)
      );
    for (let i = 0; i < countries.length; i++) {
      this.countriesArray.push(countries[i].name);
    }
    this.signupService.generatePasswordAndRewardID();
  }
  onSignup(form: NgForm) {
    console.log(form.value);
    console.log(this.password);
    console.log(this.rewardID);
    console.log("neverbounceResult: " + this.neverbounceResult);
    this.signupService.submit(form.value.email.trim(),
      form.value.first.trim(),
      form.value.last.trim(),
      form.value.country.trim(),
      form.value.zip.trim(),
      this.password,
      this.rewardID,
      this.facilityID,
      this.neverbounceResult);
  }

  onBlurMethod() {
    this.emailShow = true;
    this.isSuccessful = false;
    this.validating = true;
    this.isInvalid = false;
    this.valid = false;
    this.alreadyTaken = false;
    if (this.email !== '') {
      const emailParsed = this.email.trim();
      this.signupService.verifyEmail(emailParsed);
      this.signupService.alreadyTaken(emailParsed);
    } else {

    }
  }
  checkMark(form: NgForm) {
    if (form.value.first !== '') {
      this.firstShow = false;
    } else {
      this.firstShow = true;
    }
    if (form.value.last !== '') {
      this.lastShow = false;
    } else {
      this.lastShow = true;
    }
    if (form.value.country !== '') {
      this.countryShow = false;
    } else {
      this.countryShow = true;
    }
    if (form.value.zip !== '') {
      this.zipShow = false;
    } else {
      this.zipShow = true;
    }

  }

}
