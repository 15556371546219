import { Injectable } from '@angular/core';
import {DataService} from '../shared/data.service';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplewalletService {

  constructor(private dataService: DataService) { }

  /*  generatePass()
   *  Gets parameters 'first', 'last', 'rewardId' from the URL,
   *  then passes them to dataService.generatePass(string:last, string:first, string:rewardId),
   *  located in data.service.ts
   *  There a new Apple Wallet Pass is generated.
   *
   *  Last edited 11/30/18 by Mitch Lankford
   */
  generatePass(last: string, first: string, rewardID: string) {
    this.dataService.generatePass(last, first, rewardID).subscribe();
  }

}
