<div class="alert alert-success" role="alert" *ngIf="success">
  Your password has been sent to your registered email address
</div>
<div class="alert alert-danger" role="alert" *ngIf="failed">
  There was an error resetting your password, please check your username and reward ID. If the problem persists please contact support at <a href="mailto:Rewards@troon.com">Rewards@troon.com</a>
</div>
<mat-card class="card">
  <br>
  <mat-card-content>
    <div class="form">
      <form (ngSubmit)="onReset(f); f.resetForm()" #f="ngForm" color="accent">
        <mat-card-content>
          <h2 style="text-align: left; color: #70747C">Password Reset</h2>
          <mat-form-field>
            <mat-icon matPrefix>email</mat-icon>
            <input matInput name="email" placeholder="Email" ngModel (blur)="checkMark(f)" required>
            <mat-icon color="accent" matSuffix *ngIf="!emailShow">fiber_manual_record</mat-icon>
            <mat-icon color="accent" matSuffix *ngIf="emailShow">check_circle</mat-icon>
          </mat-form-field>
          <br>
          <mat-form-field>
            <mat-icon matPrefix>star</mat-icon>
            <input matInput name="rewardID" type="text" placeholder="Reward ID" ngModel (blur)="checkMark(f)" required>
            <mat-icon color="accent" matSuffix *ngIf="!rewardIDShow">fiber_manual_record</mat-icon>
            <mat-icon color="accent" matSuffix *ngIf="rewardIDShow">check_circle</mat-icon>
          </mat-form-field>
          <br>
          <button mat-button
                  type="button"
                  style="margin-bottom: 14px; color: #70747C; text-decoration: underline;"
                  (click)="openDialog()">
            Forgot Reward ID
          </button>
          <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        </mat-card-content>
        <br *ngIf="loading === false">
        <br>
        <br>
        <button type="submit" mat-raised-button color="primary" style="color: white; width: 100%" [disabled]="!f.valid">Submit</button>
      </form>
    </div>
  </mat-card-content>
</mat-card>
