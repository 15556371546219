import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgForm} from '@angular/forms';
import {PasswordresetService} from './passwordreset.service';
import {Subscription} from 'rxjs';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {DialogComponent} from './dialog/dialog.component';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})
export class PasswordresetComponent implements OnInit {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  email = 'test@test.com';
  password: string;
  loading = false;
  loadingSubscription: Subscription;
  successSubscription: Subscription;
  failedSubscription: Subscription;
  success = false;
  failed = false;
  emailShow = false;
  rewardIDShow = false;

  constructor(private _formBuilder: FormBuilder, private passwordResetService: PasswordresetService, public dialog: MatDialog) { }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.loadingSubscription = this.passwordResetService.loadingSubject
      .subscribe(
        (isLoading: boolean) => {
          this.loading = isLoading;
        },
        (error) => console.log(error)
      );
    this.successSubscription = this.passwordResetService.successSubject
      .subscribe(
        (isSuccess: boolean) => {
          this.success = isSuccess;
        },
        (error) => console.log(error)
      );
    this.failedSubscription = this.passwordResetService.failedSubject
      .subscribe(
        (isFailed: boolean) => {
          this.failed = isFailed;
          if (this.failed === true) {
            this.emailShow = false;
            this.rewardIDShow = false;
          }
        },
        (error) => console.log(error)
      );
  }

  checkMark(form: NgForm) {
    if (form.value.email !== '') {
      this.emailShow = true;
    } else {
      this.emailShow = false;
    }
    if (form.value.rewardID !== '') {
      this.rewardIDShow = true;
    } else {
      this.rewardIDShow = false;
    }

  }

  onReset(form: NgForm) {
    this.loading = true;
    this.success = false;
    this.failed = false;
    console.log(form.value);
    console.log(form.value.email);
    this.passwordResetService.verifyEntry(form.value.email, form.value.rewardID);
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
