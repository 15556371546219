<div class="alert alert-danger" role="alert" *ngIf="generationError">
  <div>div>There was an error generating your Apple Wallet Pass, please contact support at <a href="mailto:Rewards@troon.com">Rewards@troon.com</a></div>
</div>
<mat-card class="card" style="text-align:center">
  <mat-card-content>
    <h2 style="color: #70747C;">Apple Wallet Pass</h2>
  </mat-card-content>
  <mat-card-content layout="column" layout-alight="center center">
    <h3 *ngIf="!isSuccessful">Generating a new Apple Wallet Pass with these Credentials:</h3>
    <h4 *ngIf="!isSuccessful">First name: {{ first }}</h4>
    <h4 *ngIf="!isSuccessful">Last name: {{ last }}</h4>
    <h4 *ngIf="!isSuccessful">RewardID: {{ rewardId }}</h4>
    <mat-spinner aria-label="Generating new Apple Wallet Pass" *ngIf="!isSuccessful" style="margin:0 auto;"></mat-spinner>
    <h2 *ngIf="isSuccessful">Success! Your new Apple Wallet Pass is downloading to your device...</h2>
  </mat-card-content>
</mat-card>
