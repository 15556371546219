import { Injectable } from '@angular/core';
import {DataService} from '../shared/data.service';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  emailSubject = new Subject<any>();
  alreadyTakenSubject = new Subject<number>();
  passwordSubject = new Subject<string>();
  rewardIDSubject = new Subject<string>();
  successSubject = new Subject<boolean>();
  errorSubject = new Subject<boolean>();

  constructor(private dataService: DataService) { }

  verifyEmail(email: string) {
    this.dataService.verifyEmail(email.trim())
      .subscribe(
        (response) => {
          this.emailSubject.next(response.body['errorMessage']);
        },
        (err) => {
          console.log(err);
        }
      );
  }
  alreadyTaken(email: string) {
    this.dataService.alreadyTaken(email.trim())
      .subscribe(
        (response) => {
          this.alreadyTakenSubject.next(response.body[0].emailCount);
        },
        (err) => console.log(err)
      );
  }
  submit(email: string, first: string, last: string, country: string, zip: string, password: string, rewardID: string, facilityID: string, neverbounceResult: string) {
    this.dataService.signUp(email, first, last, country, zip, password, rewardID, facilityID, neverbounceResult)
      .subscribe(
        (response) => {
          console.log(response);
          if (response.body === 'Done!') {
            this.successSubject.next(true);
          } else {
            this.errorSubject.next(true);
          }
        },
        (err) => console.log(err)
      );
  }
  generatePasswordAndRewardID() {
    this.dataService.generatePassword()
      .subscribe(
        (response) => {
          this.passwordSubject.next(response.body.toString());
        },
        (err) => console.log(err)
      );
    this.dataService.generateRewardID()
      .subscribe(
        (response) => {
          this.rewardIDSubject.next(response.body[0].reward_id.toString());
        },
        (err) => console.log(err)
      );
  }
}
