<div class="alert alert-success" role="alert" *ngIf="isSuccessful">
  Account has been successfully created!
</div>
<div class="alert alert-danger" role="alert" *ngIf="isInvalid || alreadyTaken">
  <div *ngIf="isInvalid">There was an error setting up your account, please contact support at <a href="mailto:support@troon.zendesk.com">support@troon.zendesk.com</a></div>
  <div *ngIf="alreadyTaken">It looks like you already have an account, please reset your password <a [routerLink]="['/passwordreset']">here</a> or contact support at <a href="mailto:support@troon.zendesk.com">support@troon.zendesk.com</a></div>
</div>
<mat-card class="card">
  <mat-card-content>
    <div class="form">
      <form (ngSubmit)="onSignup(f); f.resetForm()" #f="ngForm" color="accent">
        <mat-card-content>
          <h2 style="text-align: left; color: #70747C">Sign-up for Troon Rewards</h2>
          <mat-form-field>
            <input matInput
                   type="email"
                   id="email"
                   name="email"
                   placeholder="Email"
                   [(ngModel)] = 'email'
                   (blur)="onBlurMethod()"
                   required>
            <mat-icon color="accent" matSuffix *ngIf="emailShow">fiber_manual_record</mat-icon>
            <mat-icon color="accent" matSuffix *ngIf="!emailShow">check_circle</mat-icon>
          </mat-form-field>
          <br>
          <mat-form-field>
            <input matInput name="first" placeholder="First Name" ngModel (blur)="checkMark(f)" required>
            <mat-icon color="accent" matSuffix *ngIf="firstShow">fiber_manual_record</mat-icon>
            <mat-icon color="accent" matSuffix *ngIf="!firstShow">check_circle</mat-icon>
          </mat-form-field>
          <br>
          <mat-form-field>
            <input matInput name="last" type="text" placeholder="Last Name" ngModel (blur)="checkMark(f)" required>
            <mat-icon color="accent" matSuffix *ngIf="lastShow">fiber_manual_record</mat-icon>
            <mat-icon color="accent" matSuffix *ngIf="!lastShow">check_circle</mat-icon>
          </mat-form-field>
          <br>
          <mat-form-field>
            <mat-select id="country" name="country" placeholder="Country" ngModel (blur)="checkMark(f)" required>
              <mat-option *ngFor="let country of countriesArray" [value]="country">{{country}}</mat-option>
            </mat-select>
            <mat-icon color="accent" matSuffix *ngIf="countryShow">fiber_manual_record</mat-icon>
            <mat-icon color="accent" matSuffix *ngIf="!countryShow">check_circle</mat-icon>
          </mat-form-field>
          <br>
          <mat-form-field>
            <input matInput name="zip" type="text" placeholder="Zip" ngModel (blur)="checkMark(f)" required>
            <mat-icon color="accent" matSuffix *ngIf="zipShow">fiber_manual_record</mat-icon>
            <mat-icon color="accent" matSuffix *ngIf="!zipShow">check_circle</mat-icon>
          </mat-form-field>
        </mat-card-content>

        <button type="submit" mat-raised-button color="primary" style="color: white; width: 100%" [disabled]="!f.valid || validating || isInvalid || alreadyTaken">Sign-Up!</button>
      </form>
    </div>
  </mat-card-content>
</mat-card>
