<h2 mat-dialog-title style="color: #70747C">Forgot Reward ID</h2>
<form (ngSubmit)="onReset(f); f.resetForm()" #f="ngForm" color="accent">
  <mat-dialog-content align="center">
    <mat-form-field *ngIf="!valid && !isInvalid" style="width: 75%;">
      <mat-icon matPrefix>email</mat-icon>
      <input matInput name="email" placeholder="Email" ngModel (blur)="checkMark(f)" required>
      <mat-icon color="accent" matSuffix *ngIf="!emailShow">fiber_manual_record</mat-icon>
      <mat-icon color="accent" matSuffix *ngIf="emailShow">check_circle</mat-icon>
    </mat-form-field>
    <p *ngIf="emailSuccess" style="color: #70747C">Your Troon Reward ID has been sent to your email</p>
    <p *ngIf="emailFailed" style="color: #70747C">Your request has failed, please contact support at <a href="mailto:Rewards@troon.com">Rewards@troon.com</a></p>
    <p *ngIf="isInvalid" style="color: #70747C">Invalid email</p>
    <br *ngIf="!emailSuccess">
    <mat-progress-bar mode="indeterminate" *ngIf="loading && !isInvalid"></mat-progress-bar>
    <br *ngIf="loading === false">
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close *ngIf="!valid">Cancel</button>
    <button mat-raised-button mat-dialog-close *ngIf="emailSuccess">Close</button>
    <button type="submit" mat-raised-button color="primary" style="color: white;" [disabled]="valid || emailSuccess || loading">Submit</button>
  </mat-dialog-actions>
</form>
