import { Injectable } from '@angular/core';
import {DataService} from '../../shared/data.service';
import {generate, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  email: string;
  rewardID: string = "";
  verified: boolean;
  error: boolean;
  rewardIDSubject = new Subject<string>();
  loadingSubject = new Subject<boolean>();
  successSubject = new Subject<boolean>();
  emailFailedSubject = new Subject<boolean>();
  emailSubject = new Subject<boolean>();


  constructor(private dataService: DataService) { }

  verifyEntry(email: string) {
    try {
      this.email = email;
      this.dataService.verifyEmail(email.trim())
        .subscribe(
          (response) => {
            console.log(response.body['errorMessage']);
            if (response.body['errorMessage'] === 'valid' || response.body['errorMessage'] === 'catchall' || response.body['errorMessage'] === 'unknown') {
              this.verified = true;
              this.dataService.getRewardID(email.trim())
                .subscribe(
                  (getRewardIDResponse) => {
                    try {
                      this.error = false;
                      this.successSubject.next(true);
                      this.rewardID = getRewardIDResponse.body[0].reward_id.toString();
                      this.rewardIDSubject.next(getRewardIDResponse.body[0].reward_id.toString());
                    } catch (e) {
                      console.log(e);
                      this.error = true;
                      this.loadingSubject.next(false);
                    }
                    // if error or if getRewardID returned > 1 result
                    if (this.error || getRewardIDResponse.body[1] != null || getRewardIDResponse.body[1] != undefined) {
                      console.log('error');
                      this.emailSubject.next(false);
                    } else {
                      this.dataService.rewardIDEmail(this.rewardID, this.email)
                        .subscribe(
                          (rewardIDEmailResponse) => {
                            try {
                              this.error = false;
                              this.emailSubject.next(true);
                            } catch (e) {
                              this.error = true;
                              this.emailSubject.next(false);
                            }
                          }
                        )
                    }
                  },
                  (error) => console.log(error)
                );
            } else {
              console.log('Email verification failed, please contact support');
              this.emailFailedSubject.next(true);
              this.successSubject.next(false);
              this.loadingSubject.next(false);
            }
          },
          (error) => {
            this.successSubject.next(false);
            this.loadingSubject.next(false);
          }
        );
    } catch (e) {
      console.log('Unable to verify account, please contact support');
      this.emailFailedSubject.next(true);
      this.successSubject.next(false);
      this.loadingSubject.next(false);
    }
  }

}
