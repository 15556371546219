import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SignupComponent} from './signup/signup.component';
import {PasswordresetComponent} from './passwordreset/passwordreset.component';
import {ApplewalletComponent} from './applewallet/applewallet.component';


const appRoutes: Routes = [
  { path: '', component: SignupComponent},
  { path: 'signup/:facilityID', component: SignupComponent },
  { path: 'passwordreset', component: PasswordresetComponent },
  { path: 'applewallet', component: ApplewalletComponent },
  { path: '**', component: PasswordresetComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
