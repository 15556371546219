import { Injectable } from '@angular/core';
import {DataService} from '../shared/data.service';
import {generate, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordresetService {
  email: string;
  rewardID: string;
  firstName: string;
  lastName: string;
  password: string;
  verified: boolean;
  error: boolean;
  loadingSubject = new Subject<boolean>();
  successSubject = new Subject<boolean>();
  failedSubject = new Subject<boolean>();


  constructor(private dataService: DataService) { }

  verifyEntry(email: string, rewardID: string) {
    try {
      this.email = email;
      this.rewardID = rewardID;
      this.dataService.verifyEmail(email.trim())
        .subscribe(
          (response) => {
            console.log(response.body['errorMessage']);
            if (response.body['errorMessage'] === 'valid' || response.body['errorMessage'] === 'catchall' || response.body['errorMessage'] === 'unknown') {
              this.verified = true;
              this.dataService.verifyAccount(email.trim(), rewardID.trim())
                .subscribe(
                  (verifyAccountResponse) => {
                    try {
                      this.error = false;
                      this.firstName = verifyAccountResponse.body[0].First_Name;
                      this.lastName = verifyAccountResponse.body[0].Last_Name;
                    } catch (e) {
                      console.log(e);
                      this.error = true;
                      this.failedSubject.next(true);
                      this.loadingSubject.next(false);
                    }
                    if (this.error === true) {
                      console.log('error');
                      this.failedSubject.next(true);
                      this.loadingSubject.next(false);
                    } else {
                      this.dataService.generatePassword()
                        .subscribe(
                          (generatePassword) => {
                            console.log(generatePassword.body);
                            this.password = generatePassword.body.toString();
                            console.log(this.password);
                            console.log(this.email);
                            console.log(this.firstName);
                            console.log(this.verified);
                            console.log(this.lastName);
                            console.log(this.rewardID);
                            this.dataService.resetPassword(
                              this.email,
                              this.firstName,
                              this.lastName,
                              this.password,
                              this.rewardID,
                              this.verified)
                              .subscribe(
                                (resetPassword) => {
                                  this.loadingSubject.next(false);
                                  this.successSubject.next(true);
                                  this.failedSubject.next(false);
                                },
                                (error) => this.failedSubject.next(true)
                              );
                          },
                          (error) => console.log(error)
                        );
                    }
                  },
                  (error) => console.log(error)
                );
            } else {
              console.log('Email verification failed, please contact support');
              this.failedSubject.next(true);
              this.loadingSubject.next(false);
            }
          },
          (error) => {
            this.failedSubject.next(true);
            this.loadingSubject.next(false);
          }
        );
    } catch (e) {
      console.log('Unable to verify account, please contact support');
      this.failedSubject.next(true);
      this.loadingSubject.next(false);
    }
  }
}
