import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplewalletService } from './applewallet.service';
import * as AWS from 'aws-sdk';
import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-applewallet',
  templateUrl: './applewallet.component.html',
  styleUrls: ['./applewallet.component.scss']
})
export class ApplewalletComponent implements OnInit {

  urlParams;
  first: string = "";
  last: string = "";
  rewardId: string = "";
  isSuccessful: boolean = false;
  generationError: boolean = false;

  constructor(private applewalletService: ApplewalletService, private route: ActivatedRoute) { }

  ngOnInit() {
    // generate new Apple Wallet Pass on page initialization
    this.generatePass();
  }

  /*  generatePass()
   *  Gets parameters 'first', 'last', 'rewardId' from the URL,
   *  then passes them to applewalletService.generatePass(string:last, string:first, string:rewardId),
   *  located in applewallet.service.ts
   *  There a new Apple Wallet Pass is generated.
   *  Finally waits 2 seconds then calls getNewPass(string:rewardId)
   *
   *  Last edited 11/30/18 by Mitch Lankford
   */
  generatePass() {
    // get url parameters
    this.route.queryParamMap.subscribe(params => {
      this.urlParams = {...params};
    });
    this.first = this.urlParams.params["first"];
    this.last = this.urlParams.params["last"];
    this.rewardId = this.urlParams.params["rewardID"];

    this.applewalletService.generatePass(this.last, this.first, this.rewardId);

    // wait 2 seconds for page to finish loading first
    setTimeout(() => {
      this.getNewPass(this.rewardId);
    }, 2000);
  }

  /*  getNewPass(string:rewardID)
   *  Creates an S3 object, then every 3 seconds uses it to first check if
   *  the new Apple Wallet Pass object has been created. If no, call getNewPass(string:rewardID)
   *  If yes, use 'bucket' to get the URL of the new Apple Wallet Pass object then redirect
   *  to download it. On success set isSuccessful = true, which updates applewallet.component.html
   *  On failure, update applewallet.component.html to show error banner and console.log() the error
   *
   *  Last edited 11/30/18 by Mitch Lankford
   */
  getNewPass(rewardID) {
    const bucket = new S3({
      accessKeyId: 'AKIAJTMEK26VVYMISRJA',
      secretAccessKey: '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',
      region: 'us-west-2'
    });

    const params = {
      Bucket: 'applewalletpasses',
      Key: rewardID + '.pkpass'
    }
    // wait 3 seconds for pass to generate
    setTimeout(() => {
      // check if object exists yet
      bucket.headObject(params, (err, data) => {
        if (err && err.code === 'NotFound') {
          this.getNewPass(rewardID);
        } else {
          // once object exists, retrieve url then redirect to download
          bucket.getSignedUrl('getObject', params, (err, data) => {
            if (err) {
              console.log('Error retrieving file: ', err);
              this.generationError = false;
              return false;
            } else {
              console.log('Successfully retrieved file.', data);
              this.isSuccessful = true;
              window.location.href = data;
              return true;
            }
          });
        }
      });
    }, 3000);
  }

}
